import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Payment from './Payment';
import bgImage from '../../images/bg2.avif'; // Update with the correct path to your image

// Styled components
const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${bgImage});
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UniqueFormContainer = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 30px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(25px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
`;

const UniqueFormStep = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const UniqueLabel = styled.label`
  font-weight: 600;
  color: #333;
`;

const UniqueInput = styled.input`
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const UniqueSelect = styled.select`
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const UniqueTextarea = styled.textarea`
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const UniqueButton = styled.button`
  margin-top: 15px;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  background: linear-gradient(90deg, #007bff 0%, #0056b3 100%);
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;

  &:hover {
    background: linear-gradient(90deg, #0056b3 0%, #003f88 100%);
    transform: translateY(-2px);
  }
`;

// Steps
const Step1 = ({ formData, handleChange, handleNextStep }) => (
  <UniqueFormStep>
    <h2>Enter Details</h2>
    <UniqueLabel htmlFor="name">Name:</UniqueLabel>
    <UniqueInput
      type="text"
      id="name"
      name="name"
      value={formData.name}
      onChange={handleChange}
    />
    <UniqueLabel htmlFor="paymentType">Payment Type:</UniqueLabel>
    <UniqueSelect
      id="paymentType"
      name="paymentType"
      value={formData.paymentType}
      onChange={handleChange}
    >
      <option value="rd">RD Payment</option>
      <option value="loan">Loan Repayment</option>
    </UniqueSelect>
    <UniqueLabel htmlFor="phoneNumber">Phone Number:</UniqueLabel>
    <UniqueInput
      type="tel"
      id="phoneNumber"
      name="phoneNumber"
      value={formData.phoneNumber}
      onChange={handleChange}
    />
    <UniqueLabel htmlFor="amount">Amount:</UniqueLabel>
    <UniqueInput
      type="number"
      id="amount"
      name="amount"
      value={formData.amount}
      onChange={handleChange}
    />
    <UniqueLabel htmlFor="remarks">Remarks:</UniqueLabel>
    <UniqueTextarea
      id="remarks"
      name="remarks"
      value={formData.remarks}
      onChange={handleChange}
    />
    <UniqueButton type="button" onClick={handleNextStep}>Next</UniqueButton>
  </UniqueFormStep>
);

const Step2 = ({ formData, handleNextStep, handlePreviousStep }) => (
  <UniqueFormStep>
    <h2>Confirm Details</h2>
    <p><strong>Name:</strong> {formData.name}</p>
    <p><strong>Payment Type:</strong> {formData.paymentType}</p>
    <p><strong>Phone Number:</strong> {formData.phoneNumber}</p>
    <p><strong>Amount:</strong> {formData.amount}</p>
    <p><strong>Remarks:</strong> {formData.remarks}</p>
    <UniqueButton type="button" onClick={handlePreviousStep}>Back</UniqueButton>
    <UniqueButton type="button" onClick={handleNextStep}>Proceed to Payment</UniqueButton>
  </UniqueFormStep>
);

const Step3 = ({ formData, handlePaymentSuccess, handlePreviousStep }) => (
  <UniqueFormStep>
    <Payment
      upiID="supreetsouharda@kbl"
      amount={formData.amount}
      onPaymentComplete={handlePaymentSuccess}
    />
    <UniqueButton type="button" onClick={handlePreviousStep} style={{ marginTop: '20px' }}>
      Back
    </UniqueButton>
  </UniqueFormStep>
);

const Step4 = () => (
  <UniqueFormStep>
    <h2>Payment Successful!</h2>
    <p>Your payment has been processed successfully. Thank you!</p>
  </UniqueFormStep>
);

const FormManager = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    paymentType: '',
    phoneNumber: '',
    amount: '',
    remarks: '',
  });

  useEffect(() => {
    const paymentState = localStorage.getItem('paymentState');
    if (paymentState) {
      localStorage.removeItem('paymentState');
      setStep(4); // Proceed to Step 4 assuming payment is completed
    }
  }, []);

  const handleNextStep = () => setStep(step + 1);
  const handlePreviousStep = () => setStep(step - 1);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePaymentSuccess = () => {
    handleNextStep(); // Move to Step 4 after payment completion
  };

  return (
    <PageContainer>
      <UniqueFormContainer>
        {step === 1 && (
          <Step1
            formData={formData}
            handleChange={handleChange}
            handleNextStep={handleNextStep}
          />
        )}
        {step === 2 && (
          <Step2
            formData={formData}
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
          />
        )}
        {step === 3 && (
          <Step3
            formData={formData}
            handlePaymentSuccess={handlePaymentSuccess}
            handlePreviousStep={handlePreviousStep}
          />
        )}
        {step === 4 && <Step4 />}
      </UniqueFormContainer>
    </PageContainer>
  );
};

export default FormManager;
