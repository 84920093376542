import React, { useState, useEffect } from 'react';
import { Container, Wrapper, Title, Desc, CardContainer, ToggleButtonGroup, ToggleButton, Divider, LoadingContainer } from './MobileStyle';
import ProjectCard from '../Cards/Mobilecard';
import { CircularProgress, Typography } from '@mui/material';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const Projects = ({ openModal, setOpenModal }) => {
  const [toggle, setToggle] = useState('all');
  const [projects, setProjects] = useState([]);
  const [newProjects, setNewProjects] = useState([]);
  const [additionalProjects, setAdditionalProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [servicesTitle, setServicesTitle] = useState('');
  const [newServicesTitle, setNewServicesTitle] = useState('');
  const [additionalServicesTitle, setAdditionalServicesTitle] = useState('');

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'services'));
        const fetchedProjects = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setProjects(fetchedProjects);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    const fetchNewProjects = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'loans'));
        const fetchedProjects = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setNewProjects(fetchedProjects);
      } catch (error) {
        console.error('Error fetching new projects:', error);
      }
    };

    const fetchAdditionalProjects = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'other-services'));
        const fetchedProjects = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setAdditionalProjects(fetchedProjects);
      } catch (error) {
        console.error('Error fetching additional projects:', error);
      }
    };

    const fetchServicesTitle = async () => {
      try {
        const docRef = doc(db, 'title-deposit', 'depositText');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setServicesTitle(docSnap.data().text);
        }
      } catch (error) {
        console.error('Error fetching services title:', error);
      }
    };

    const fetchNewServicesTitle = async () => {
      try {
        const docRef = doc(db, 'title-loan', 'loanText');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setNewServicesTitle(docSnap.data().text);
        }
      } catch (error) {
        console.error('Error fetching new services title:', error);
      }
    };

    const fetchAdditionalServicesTitle = async () => {
      try {
        const docRef = doc(db, 'title-others', 'otherText');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setAdditionalServicesTitle(docSnap.data().text);
        }
      } catch (error) {
        console.error('Error fetching additional services title:', error);
      }
    };

    fetchProjects();
    fetchNewProjects();
    fetchAdditionalProjects();
    fetchServicesTitle();
    fetchNewServicesTitle();
    fetchAdditionalServicesTitle();
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <LoadingContainer>
        <CircularProgress color="secondary" />
        <Typography variant="h6" sx={{ mt: 2, color: 'white' }}>Please wait...</Typography>
      </LoadingContainer>
    );
  }

  const renderProjects = (title, projects) => (
    <Wrapper>
      <Title>{title}</Title>
      
      <CardContainer>
        {projects
          .filter((item) => toggle === 'all' || item.category === toggle)
          .map((project) => (
            <ProjectCard key={project.id} project={project} openModal={openModal} setOpenModal={setOpenModal} />
          ))}
      </CardContainer>
    </Wrapper>
  );
  return (
    <Container id="projects">
      {renderProjects(servicesTitle, projects)}
      {renderProjects(newServicesTitle, newProjects)}
      {renderProjects(additionalServicesTitle, additionalProjects)}
    </Container>
  );
}

export default Projects;
